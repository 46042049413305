.form_search_item,.form_search_customer,.form_search_area {
    width: 100%;
    position: relative;
    // @include respond(tab-port) {
    //     width: 60%;
    // }
    // @include respond(phone) {
    //     width: 100%;
    // }
    
    // & ul div{
    //     display: none;
    // }
    & svg {
        position: absolute;
        font-size: 1.6rem;
        top: 45%;
        transform: translate(-50%, -50%);
        left: 2rem;
    }
    &_search-list {
        
        z-index: 8;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
        width: 100%;
        position: absolute;
        left: 0;
        background-color: #ffff;
        max-height: 20rem;
        overflow-y: auto;
        list-style-type: none;
        font-size: 1.6rem;
        & > :not(:last-child) {
            border-bottom: 0.1rem solid $color-primary;
        }
        &_item {
            position: relative;
            cursor: pointer;
            width: 100%;
            padding: .5rem;
            height: min-content;
            background-color:$color-grey-light-1 ;
            
            &_details{
                & > *{
                display: inline-block;
                }
            }
            &_img{
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}


// .form_search_item:focus-within,.form_search_customer:focus-within,.form_search_area:focus-within{ 
//     & ul div{
//         display: block !important;
//     }
// }

// .form_search_customer:focus-within{ & ul{
//     display: block !important;
// }
// }


.item-qty{
    display: flex;
    
    align-items: center;
    & > * {
        @include respond(phone) {
      
            font-size: 1rem;
        }
    }
}

//extera

  .item {
    border: 1px solid rgb(235, 244, 247);

  }
  
  .item.active,
  .item:hover {
    background: #6e6c6c3b;
  }
  
  .item:hover {
    cursor: pointer;
  }

  // jquery show hide classes
  .show{
      display: block !important;
  }
  .hide{
      display: none !important;
  }
