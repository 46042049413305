//color variables
 
$color-grey-dark: #131d2b;
$color-grey-light: #e9e9e9;
$color-grey: #777777;

$bg-color: #f1f1f1;
$primary-color: #000;
$highlight-color: #F50057;
$light-color: #fff;
$border-color: #ddd;

// other COLORS
$color-primary: #28528F;
$color-primary-light: #739fdf;
$color-primary-dark: #1d3960;

$color-secondary: #f50057;
$color-secondary-light:#f73378;
$color-secondary-dark: #ab003c;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

//margins

$margin-big: 8rem;
$margin-huge: 10rem;
$margin-medium: 4rem;
$margin-small: 2rem;

//paddings

$padding-big: 8rem;
$padding-huge: 10rem;
$padding-medium: 4rem;
$padding-small: 2rem;
