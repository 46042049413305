

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	font-family: 'Montserrat', sans-serif !important;
}

.App{
	overflow: hidden;
}

html {
	// This defines what 1rem is
	font-size: 72.5%; //1 rem = 10px; 10px/16px = 62.5%

	@include respond(tab-land) {
		// width < 1200?
		font-size: 66.25%; //1 rem = 9px, 9/16 = 50%
	}

	@include respond(tab-port) {
		// width < 900?
		font-size: 60%; //1 rem = 8px, 8/16 = 50%
	}

	@include respond(big-desktop) {
		font-size: 85%; //1rem = 12, 12/16
	}
}

body {
	box-sizing: border-box;
	overflow-x: hidden;
	@include respond(tab-port) {
		padding: 0;
	}
	a,
	a:hover,
	a:focus,
	a:active {
		color: inherit;
		text-decoration: none;
	}
}

.active li{
		background-color:$color-primary !important;
		& svg{
			fill: white !important;
		}
	}

.primary{
	color: $color-primary;
}
::-webkit-scrollbar {
	width: 10px;
  }
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey; 
	// border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: $color-secondary-light; 
	// border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: $color-secondary
  }
.table-container {
	
	max-width: 100%;
	margin: 0 auto 0;
	// padding-left: 1rem;
	position:relative;
	overflow-y:auto;
	&__table {
	  height: min-content;
	  width: 100%;
	  border-collapse: collapse;
	  border: 1.2rem;
	  tbody{
		tr {
			&:nth-child(odd) {

				background-color: $bg-color;
		}
		  }
	  }
	  thead {
  
		tr {
			
			background-color: $color-primary-light ;
		  th{
			color:$color-white;
			font-weight: 900;
			
		  }
		}
	  }
  
	  td, th {
		border: none;
		padding: .2rem ;
		padding-left: .5rem;
		font-size: 1.1rem;
		
		@include respond(tab-land){
			font-size: 1.1rem;
		}
		@include respond(tab-port){
			font-size: 1.1rem;
		}
		@include respond(phone){
			font-size: 1rem;
			padding: .5rem 1rem  ;
		}
		text-align: left;
	  }
  
  
	  &--break-md {
		position:relative;
		@include respond(tab-port){
			tbody{
				tr {
					padding:1rem;
				  }
			  }
			thead {
				display: none;
			  }
			  tr {
				
				display: block;
				margin-bottom: 5px;
				
			  }
			
			  td {
				display: block;
				position: relative;
				padding-left: 130px;
				text-align: left;
				border-bottom: 0;
				margin-top : 1rem;
			
				&:last-child {
				  border-bottom: 1px solid $border-color;
				}
			
				&::before {
				  content: attr(data-heading);
				  position: absolute;
				  top: .5rem;
				  left: 0;
				  width: 120px;
				  height: 100%;
				  display: flex;
				  align-items: left;
				  color: $color-grey-dark-3;
				  font-size: 1rem;
				  text-transform: capitalize;
				  @include respond(phone){
					  
					font-size: 1rem;
				}
				  padding: 0 .2rem;
				  justify-content: left;
				}
			  }
		}
	  }
  
	//   &--break-sm {
  
	// 	@media (max-width: 575px) {
	// 	  @include break;
	// 	}
	//   }
	}
  }

  .Simple_Solutions_Logo{
	word-spacing: 3px;
	font-weight:bolder;
	border-bottom:.2rem solid #28528F;
	padding:.2rem;
	& .Simple_Solutions_Logo_child{
		font-size: 1rem !important;
		
		animation: 3s ease-in 1s infinite reverse both running SimpleSolutionsLogo;
	}
  }

  
  @keyframes SimpleSolutionsLogo {
	from {color: #28528F;}
	to {color: #7EC5E4;}
  }


//print.js styles
@media screen {

.invoice-box{
	height: max-content;
}
.details-div{
	width: 100%;
	margin: auto;
	
   
}
.heading-div{
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1rem solid $color-primary;
	@include respond(phone){
		flex-direction: column;	
		border-bottom: none;
	}
}
.Main-Heading{
font-weight: bolder;
color: $color-primary;
font-size: 4.6rem;
width: 50%;
float: left;
@include respond(phone){
	width: 100%;
	float: none;	
}
}
.sub-Heading{
display: flex;
flex-direction: column;
align-items: flex-start;
width: fit-content;
float: right;
position: relative;
& .date{
	margin-bottom: 1rem;
}
& h3{
	width: fit-content;
}
@include respond(phone){
	align-items: flex-start;
	width: 100%;
	float: none;
	
}

}

.inner-wrapper{
	width: 100%;
}

.customer-div {
  width: 49%;
  display: inline;
  float: left;
  height: min-content;
  & > *{
	  padding: 1rem 1rem 1rem 0rem;
  }
  @include respond(phone){
	width: 100%;
	float: none;	
  }
}

h6{
  font-size: 1rem;
  font-weight: bolder;
}


label{
  text-transform: uppercase;
  color: $color-primary;
}
.order-div {
  width: 49%;
  display: inline;
  float:right;
  height: min-content;
  & > *{
	  padding: 1rem 1rem 1rem 0rem ;
  }
  @include respond(phone){
	width: 100%;
	float: none;	
}
}
.item-table {
border-collapse: collapse;
border: none;
max-height: 13rem;
overflow-y: auto;
font-size: 1.2rem;
width: 100%;
}

.item-table td, .item-table th {

padding: 8px;
}

.item-table tr:nth-child(even){background-color: #f2f2f2;}

.item-table tr:hover {background-color: #ddd;}

.item-table th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
border-top: .2rem solid $color-primary;
border-bottom: .2rem solid $color-primary;

}

.signature{
	color: $color-primary;
	margin-top: 5rem !important;
	width: fit-content;
	font-weight:bolder !important;
}
}

@media print {
	.invoice-box{
		height: max-content;
	}
	.details-div{
		width: 100%;
		margin: auto;
		
	   
	}
	.heading-div{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1rem solid $color-primary;
		@include respond(phone){
			flex-direction: column;	
			border-bottom: none;
		}
	}
	.Main-Heading{
	font-weight: bolder;
	color: $color-primary;
	font-size: 4.6rem;
	width: 50%;
	float: left;
	@include respond(phone){
		width: 100%;
		float: none;	
	}
	}
	.sub-Heading{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: fit-content;
	float: right;
	position: relative;
	& .date{
		margin-bottom: 1rem;
	}
	& h3{
		width: fit-content;
	}
	@include respond(phone){
		align-items: flex-start;
		width: 100%;
		float: none;
		
	}
	
	}
	
	.inner-wrapper{
		width: 100%;
	}
	
	.customer-div {
	  width: 49%;
	  display: inline;
	  float: left;
	  height: min-content;
	  & > *{
		  padding: 1rem 1rem 1rem 0rem;
	  }
	  @include respond(phone){
		width: 100%;
		float: none;	
	  }
	}
	
	h6{
	  font-size: 1rem;
	  font-weight: bolder;
	}
	
	
	label{
	  text-transform: uppercase;
	  color: $color-primary;
	}
	.order-div {
	  width: 49%;
	  display: inline;
	  float:right;
	  height: min-content;
	  & > *{
		  padding: 1rem 1rem 1rem 0rem ;
	  }
	  @include respond(phone){
		width: 100%;
		float: none;	
	}
	}
	.item-table {
	border-collapse: collapse;
	font-size: 1.2rem;
	border: none;
	width: 100%;
	}
	
	.item-table td, .item-table th {
	
	padding: 8px;
	}
	
	.item-table tr:nth-child(even){background-color: #f2f2f2;}
	
	.item-table tr:hover {background-color: #ddd;}
	
	.item-table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	border-top: .2rem solid $color-primary;
	border-bottom: .2rem solid $color-primary;
	
	}
	
	.signature{
		color: $color-primary;
		margin-top: 5rem !important;
		width: fit-content;
		font-weight:bolder !important;
	}
	header{
		display: none !important;
	}
	button{
		display: none !important;
	}

	.MuiDrawer-root,.MuiDrawer-docked{
		display: none !important;
	}

	.MuiPaper-root {
		border: none !important;
		box-shadow: none !important;
	}
	.makeStyles-toolbar-13{
		display: none !important;
	}
	.signature{
		color: $color-primary;
		margin-top: 5rem !important;
		width: fit-content;
		font-weight:bolder !important;
	}
}


//Buttoncolors

.colorPrimary{
	background-color: $color-primary-light !important;
	&:hover{
		background-color: $color-primary !important;
	}
}

.colorSecondary{
	background-color: $bg-color !important;
	&:hover{
		background-color: $color-secondary-light !important;
	}
}